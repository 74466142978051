import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Making ASB more diverse`}</h1>
    <p>{`We need to make our school leadership more diverse. In every way. This means getting people from all different kinds of backgrounds, experiences, clubs, programs, and more. A lot of the time, ASB students are ones that have been in Class Council and Student Forum, but I think that needs to change. Council and Forum are two fantastic ways students can learn important skills and prepare them for ASB or any other leadership role. But those should not be requirements, nor should they be standards.`}</p>
    <p>{`You can learn leadership skills from being in marching band, you can learn leadership skills from being in yearbook! We need to have a diverse ASB, meaning that we pick students that are in different areas in campus, and will be able to represent them when needed.`}</p>
    <p>{`I want to make giant murals in the student center, I want people to watch eSports tournaments like they do football games, I want to make gigantic structures like University High does. We can only do that if we have a good connection with each part of campus. We need to work well with art, we need to work well with the gaming community, and we need to work well with other schools.`}</p>
    <p>{`If you select me to be your Irvine High School ASB President, you can trust that I'll help pick out an ASB that is going to be hard working, represent every part of campus, and believe in your voice.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      